import { createGlobalStyle } from "styled-components";

const Buttons = createGlobalStyle`
    .btn--rounded {
        display: grid;
        place-content: center;
        height: 64px;
        padding: 16px 32px;
        font-weight: 500;
        font-size: 20px;
        line-height: 100%;
        letter-spacing: 0.04em;
        border-radius: 100px;
        border: 1px solid;
        width: fit-content;
        &.btn-home-page {
            height: 56px;
        }

        &.btn--rounded--slim {
            padding: 8px 20px;
        }
        &.w-full {
            display: inline-block;
            width: 100%;
            text-align: center;
        }
        &.btn--inverted {
            background-color: var(--white);
            color: var(--primary-dark);
        }
        &.btn--dark {
            background-color: var(--primary-dark);
            color: var(--white);
        }
        &.btn--big {
            background: rgba(0, 0, 0, 0.1);
            border: 1px solid rgba(0, 0, 0, 0.2);
            border-radius: 100px;
            padding: 24px 32px;
        }
        &.btn--transparent-light-text {
            color: var(--white);
        }
        &.btn--icon-left {
            display: grid;
            grid-template-columns: min-content max-content;
            grid-column-gap: 8px;
        }

        &.btn--icon-right {
            display: grid;
            grid-template-columns: max-content min-content;
            grid-column-gap: 8px;
        }
        &.light-icon {
            path {
                fill: #fff;
            }
        }

        &.btn--shortcut {
            padding: 8px 12px;
            gap: 8px;
            width: fit-content;
            height: 40px;
            border-radius: 8px;
            font-size: 20px;
            border: none;
        }

        &.btn--shortcut-light {
            background: #EBEBEB;
            color: black;
        }

        &.btn--shortcut-dark {
            background: rgba(235, 235, 235, 0.2);
            color: white;
        }
    }

    
    .btn--bg {
        border: 0;
        &.btn--bg--white {
            --bg: var(--white);
            --bg-rgb: var(--white-rgb);
            --o: 0.95;
        }
        &.btn--bg--primary-dark {
            --bg: var(--primary-dark);
            --bg-rgb: var(--primary-dark-rgb);
            --o: 0.95;
        }
     

        background-color: var(--bg);
        &:hover, &:focus {
            background-color: rgba(var(--bg-rgb),var(--o));
        }
    }
            
    .btn--border {
        &.btn--border--white {
            --color: var(--white);
        }
        &.btn--border--primary-dark {
            --color: var(--primary-dark);
        }
      
        background-color: transparent;
        border-color: var(--color);
        &:hover {
            border-color: var(--white);
            &.btn--border--grey-4 {
                border-color: var(--grey-6);
            }
        }
    }
    

    .btn--square {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 8px 24px;
        gap: 16px;
        height: 56px;
        background:  #111111;
        border: 2px solid #444444;
        border-radius: 8px;
        color: white;
        font-weight: 500;
        font-size: 20px;
    }

    .btn--square.primary {
        background-color: black;
    }

    .btn--square.secondary {
        border: 2px solid #E8E8E8;
        background: #F2F2F2;
        color: #111111;
    }



    @keyframes line-in{
        0%{transform:translate3d(-101%,0,0)}
        to{transform:translateZ(0)}
    }
    
    @keyframes line-out{
        0%{transform:translateZ(0)}
        to{transform:translate3d(101%,0,0)}
    }
    

  


`;

export default Buttons;
