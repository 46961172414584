import React, { useMemo, useState } from "react";
import {
  QuickSearch,
  ObjectResult,
  ResultItemGroup,
} from "@atlaskit/quick-search";
import { navigate } from "gatsby-link";
import styled from "styled-components";
import { useRef } from "react";
const PageStyles = styled.div`
  z-index: 100;
  & > :first-child {
    padding: 0;
  }

  &
    > :first-child
    > :nth-child(2)
    > :first-child
    > :first-child
    > :first-child {
    padding: 0;
    margin: 0;
  }

  &
    > :first-child
    > :nth-child(2)
    > :first-child
    > :first-child
    > :first-child {
    padding: 0;
    margin: 0;
  }
  & > :nth-child(2) {
    padding: 0;
  }
  & > :nth-child(2) > :nth-child(2) > :first-child * {
    margin: 0;
    padding: 0;
  }
  .show {
    display: block;
  }
  .hide {
    display: none;
  }
`;
const Search = ({ queryData }) => {
  const [loading, setLoading] = useState();
  const [search, setSearch] = useState("");

  const ref = useRef();
  const edges = useMemo(
    () =>
      queryData.allShortTermRentalSetAverages.edges
        .filter((edge) => {
          return (
            search &&
            edge.node.shortTermRentalGroup.city
              .toLowerCase()
              .includes(search.toLowerCase())
          );
        })
        .slice(0, 5),
    [search]
  );
  return (
    <div className="relative w-full">
      <PageStyles className="absolute w-full top-0 left-0 z-20">
        <QuickSearch
          ref={ref}
          isLoading={false}
          onSearchInput={({ target }) => {
            setSearch(target.value);
          }}
          onSearchBlur={() => setSearch("")}
        >
          {search && (
            <ResultItemGroup style={{}} title="Rental Markets">
              {edges.map((edge, i) => {
                if (edge)
                  return (
                    <span key={`/${edge.node.shortTermRentalGroup.slug}`}>
                      <ObjectResult
                        onClick={() => {
                          navigate(`/${edge.node.shortTermRentalGroup.slug}`);
                        }}
                        resultId={i}
                        name={edge.node.shortTermRentalGroup.city}
                        objectKey={edge.node.shortTermRentalGroup.city}
                      />
                    </span>
                  );
              })}
            </ResultItemGroup>
          )}
        </QuickSearch>
      </PageStyles>
    </div>
  );
};

export default Search;
