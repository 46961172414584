import { createGlobalStyle } from "styled-components";
import { media } from "./media";

const Form = createGlobalStyle`
    .input--primary {
        
    }
    .search-container {
        padding: 16px 0px 0px;

        &.search-container-dark {
            background-color: var(--primary-dark);
        }

    .search-bar {
        box-sizing: border-box;
        display: flex;
        max-height: 72px;
        height: 100%;
        background: #FFFFFF;
        border: 2px solid rgba(0, 0, 0, 0.2);
        border-radius: 8px;
        padding: 24px;

    

        .input {
            height: 100%;
            width: 100%;
            outline: none;
        }

        .search-result {
            display: grid;
            grid-template-columns: min-content 1fr max-content;
            grid-column-gap: 24px;
        }

        .search-result:not(:first-child) {
            margin-top: 24px;
            margin-bottom: 24px;
        }
        

        .search-dropdown {
            background: #FFFFFF;
        }



        &.search-bar-dark {
            border: 1px solid rgba(255, 255, 255, 0.2);
            background: rgba(255, 255, 255, 0.06);
            .input {
                background: transparent;
                color: rgba(255, 255, 255, 0.6);
            }
            .search-dropdown {
                background: var(--primary-dark)

            }
            .search-result {

            }
        }

    }
}

`;

export default Form;
