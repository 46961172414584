import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { Helmet } from "react-helmet";
import Nav from "./Nav";
import Typography from "../styles/Typography";
import Grid from "../styles/Grid";
import Buttons from "../styles/Buttons";
import GlobalStyles from "../styles/GlobalStyles";
import Form from "../styles/Form";
import useElementSize from "../hooks/useElementSize";

export const Layout = ({ children }) => {
  const queryData = useStaticQuery(graphql`
    query SiteQuery {
      site {
        siteMetadata {
          title
          description
        }
      }
      allShortTermRentalSetAverages {
        edges {
          node {
            name
            shortTermRentalGroup {
              url
              city
              slug
            }
          }
        }
      }
    }
  `);
  const [ref, { width, height }] = useElementSize();
  const [ref2, { w, h }] = useElementSize();

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />

        <meta
          name="viewport"
          content="height=device-height, 
                      width=device-width, initial-scale=1.0, 
                      minimum-scale=1.0, maximum-scale=1.0, 
                      user-scalable=no, target-densitydpi=device-dpi"
        ></meta>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link
          href="https://fonts.googleapis.com/css2?family=Barlow+Condensed:wght@100;200;300;400;500;600;700;800;900&display=swap"
          rel="stylesheet"
        />
      </Helmet>

      <Nav queryData={queryData} />
      <div></div>
      <div style={{ marginTop: 30 }}>{children}</div>
      <GlobalStyles />
      <Typography />
      <Grid />
      <Buttons />
      <Form />
    </div>
  );
};
/**
      <div ref={ref} className="fixed top-0 left-0 w-full z-10"></div>
 * 
 *   <script
          dangerouslySetInnerHTML={{
            __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-MK7LF35');`,
          }}
        ></script>
            <noscript>
        <iframe
          src="https://www.googletagmanager.com/ns.html?id=GTM-MK7LF35"
          height="0"
          width="0"
          style="display:none;visibility:hidden"
        ></iframe>
      </noscript>
 */

/**
       *               <a
                  key={`/${edge.node.shortTermRentalGroup.slug}`}
                  style={{ padding: 5, backgroundColor: "#000", color: "#fff" }}
                  href={`/${edge.node.shortTermRentalGroup.slug}`}
                >
                  {strToTitleCase(edge.node.name)}
                </a>

                <motion.a
                  style={{ padding: 5, backgroundColor: "#000", color: "#fff" }}
                  key={`/airbnb-guide/${edge.node.shortTermRentalGroup.slug}`}
                  href={`/airbnb-guide/${edge.node.shortTermRentalGroup.slug}`}
                  className="linking-item"
                >
                  Airbnb Guide {strToTitleCase(edge.node.name)}
                  <span
                    style={{
                      display: "block",
                      opacity: 0,
                      width: 0,
                      height: 0,
                    }}
                  >
                    {" "}
                    AIRBNB Strategy VRBO Strategy VRBO Guide Short Term Rental
                    Guide Short Term Rental Strategy
                  </span>
                </motion.a>
                <motion.a
                  style={{ padding: 5, backgroundColor: "#000", color: "#fff" }}
                  key={`/airbnb-statistics/${edge.node.shortTermRentalGroup.slug}`}
                  href={`/airbnb-statistics/${edge.node.shortTermRentalGroup.slug}`}
                  className="linking-item"
                >
                  Airbnb Statistics {strToTitleCase(edge.node.name)}
                </motion.a>
                <motion.a
                  style={{ padding: 5, backgroundColor: "#000", color: "#fff" }}
                  key={`/airbnb-consultant-${edge.node.shortTermRentalGroup.slug}`}
                  href={`/airbnb-consultant-${edge.node.shortTermRentalGroup.slug}`}
                  className="linking-item"
                >
                  Airbnb Consultant {strToTitleCase(edge.node.name)}
                </motion.a>
                <motion.a
                  style={{ padding: 5, backgroundColor: "#000", color: "#fff" }}
                  key={`/services/airbnb/co-host/${edge.node.shortTermRentalGroup.slug}`}
                  href={`/services/airbnb/co-host/${edge.node.shortTermRentalGroup.slug}`}
                  className="linking-item"
                >
                  Airbnb Co-Host {strToTitleCase(edge.node.name)}
                </motion.a>


                    <motion.a
                  style={{ padding: 5, backgroundColor: "#000", color: "#fff" }}
                  key={`/services/airbnb/co-host/${edge.node.shortTermRentalGroup.slug}`}
                  href={`/services/airbnb/co-host/${edge.node.shortTermRentalGroup.slug}`}
                  className="linking-item"
                >
                  Airbnb Co-Host {strToTitleCase(edge.node.name)}
                </motion.a>


                  <Marquee
          gradient={false}
          style={{
            backgroundColor: "#000",
            position: "fixed",
            top: 0,
            left: 0,
            zIndex: 9999,
          }}
        >
          {queryData.allShortTermRentalSetAverages.edges.map((edge) => {
            return (
              <>
                <motion.a
                  style={{ padding: 5, backgroundColor: "#000", color: "#fff" }}
                  key={`/airbnb-consultant-${edge.node.shortTermRentalGroup.slug}`}
                  href={`/airbnb-consultant-${edge.node.shortTermRentalGroup.slug}`}
                  className="linking-item"
                >
                  Airbnb Consultant {strToTitleCase(edge.node.name)}
                </motion.a>
              </>
            );
          })}
        </Marquee>
       */
