exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-airbnb-co-host-js": () => import("./../../../src/templates/airbnb-co-host.js" /* webpackChunkName: "component---src-templates-airbnb-co-host-js" */),
  "component---src-templates-airbnb-consulting-js": () => import("./../../../src/templates/airbnb-consulting.js" /* webpackChunkName: "component---src-templates-airbnb-consulting-js" */),
  "component---src-templates-airbnb-optimization-js": () => import("./../../../src/templates/airbnb-optimization.js" /* webpackChunkName: "component---src-templates-airbnb-optimization-js" */),
  "component---src-templates-city-js": () => import("./../../../src/templates/city.js" /* webpackChunkName: "component---src-templates-city-js" */),
  "component---src-templates-homepage-js": () => import("./../../../src/templates/homepage.js" /* webpackChunkName: "component---src-templates-homepage-js" */),
  "component---src-templates-interior-design-js": () => import("./../../../src/templates/interior-design.js" /* webpackChunkName: "component---src-templates-interior-design-js" */),
  "component---src-templates-market-set-js": () => import("./../../../src/templates/market-set.js" /* webpackChunkName: "component---src-templates-market-set-js" */),
  "component---src-templates-statistics-js": () => import("./../../../src/templates/statistics.js" /* webpackChunkName: "component---src-templates-statistics-js" */)
}

