import { createGlobalStyle } from "styled-components";
import { media } from "./media";

import InterThin from "../assets/fonts/Inter-Thin.woff";
import InterRegular from "../assets/fonts/Inter-Regular.woff";
import InterMedium from "../assets/fonts/Inter-Medium.woff";
import InterSemiBold from "../assets/fonts/Inter-SemiBold.woff";

import InterBold from "../assets/fonts/Inter-Bold.woff";

const Typography = createGlobalStyle`


    @font-face {
        font-family: 'Inter';
        src: url(${InterThin}) format('woff');
        font-weight: 300;
        font-style: normal;
        font-display: block;
    }

    @font-face {
        font-family: 'Inter';
        src: url(${InterRegular}) format('woff');
        font-weight: normal;
        font-style: normal;
        font-display: block;
    }

    @font-face {
        font-family: 'Inter';
        src: url(${InterMedium}) format('woff');
        font-weight: 500;
        font-style: normal;
        font-display: block;
    }
    

    
    @font-face {
        font-family: 'Inter';
        src: url(${InterSemiBold}) format('woff');
        font-weight: 600;
        font-style: normal;
        font-display: block;
    }

    @font-face {
        font-family: 'Inter';
        src: url(${InterBold}) format('woff');
        font-weight: 700;
        font-style: normal;
        font-display: block;
    }

    html {
        font-family: 'Inter', 'Times New Roman', 'Open Sans', 'Helvetica Neue', sans-serif;
    }

    .h1 {
        font-weight: 500;
        font-size: 48px;
        line-height: 68px;
        letter-spacing: -0.01em;
        @media ${media.mdUp} { 
            font-size: 56px;
        }
        @media ${media.lgUp} { 
            font-size: 56px;
        }
    }
    eyebrow, .eyebrow {
        font-weight: 600;
        font-size: 10px;
        line-height: 100%;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        &.line {
            position: relative;
            &::after {
                position: absolute;
                content: '';
                width: 25px;
                height: 1px;
                margin: 5px 0 0 5px;
                background-color: currentColor;
            }
        }
    }
    .h2 {
        font-weight: 500;
        font-size: 40px;
        letter-spacing: -0.01em;
        line-height: 44px;
        @media ${media.mdUp} { 
            font-size: 40x;
        }
        @media ${media.lgUp} { 
            font-size: 40px;
        }
    }
    .h3 {
        font-weight: 500;
        font-size: 24px;
        line-height: 110%;
        letter-spacing: -0.01em;
        @media ${media.mdUp} { 
            font-size: 32px;
        }
        @media ${media.lgUp} { 
            font-size: 32px;
        }
        &.normal {
        font-weight: normal;
        }
    }
    .h4 {
        font-weight: 500;
        font-size: 20px;
        line-height: 110%;
        letter-spacing: -0.01em;
        @media ${media.mdUp} { 
            font-size: 24px;
        }
        @media ${media.lgUp} { 
            font-size: 24px;
        }
    }
    .h5 {
        font-weight: 500;
        font-size: 18px;
        line-height: 110%;
        letter-spacing: 0.01em;
        @media ${media.mdUp} { 
            font-size: 18px;
            line-height: 130%;
        }
        @media ${media.lgUp} { 
            font-size: 18px;
        }
    }
    .h6 {
        font-weight: 500;
        font-size: 16px;
        line-height: 125%;
        letter-spacing: 0.01em;
        @media ${media.mdUp} { 
            font-size: 16px;
        }
        @media ${media.lgUp} { 
            font-size: 16px;
        }
    }
    p {
        margin-bottom: 16px;
        // font-weight: 400;
        line-height: 150%;
        letter-spacing: 0.01em;
        @media ${media.mdUp} { 
            line-height: 160%;
        }
    }

    ul.tiny {
        padding: 0 0 0 14px;
        li {
            list-style: disc;
        }
    }
    ol {
        list-style: decimal;
        padding: 0 0 0 1rem;
        margin: 0 0 2rem;
    }


    

`;

export default Typography;
