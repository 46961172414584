import "./src/css/global.css";
import React, { useEffect } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Layout } from "./src/components/layout";

gsap.registerPlugin(ScrollTrigger);

export function wrapPageElement({ element, props }) {
  return <Layout {...props}>{element}</Layout>;
}
