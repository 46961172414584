import React, { Component, useEffect } from "react";
import styled from "styled-components";
import { gsap } from "gsap";
import { Link } from "gatsby";
import Search from "./organisms/search/search";

const Container = styled.div`
  opacity: 0;
`;

const NavStyles = styled.nav`
  padding-top: 20px;
  padding-bottom: 20px;
  gap: 0;
  min-height: 96px;
  background: transparent;
  align-items: center;
`;

const Nav = ({ queryData }) => {
  useEffect(() => {
    const fadeElements = gsap.timeline();
    fadeElements.to(".nav--container", {
      opacity: 1,
      delay: 1.5,
    });
  }, []);
  return (
    <Container className={`nav--container bg-white border bottom-black`}>
      <NavStyles className="container grid grid-cols-4 md:grid-cols-8 lg:grid-cols-12">
        <div className="row-start-1 col-span-2 md:col-span-2 lg:col-span-4 h-[96px] grid items-center">
          <Link to="/">
            <div className={`text-black text-[20px] `}>RentalGuideAI</div>
          </Link>
        </div>

        <div className="row-start-2 md:row-start-1 col-span-full md:col-span-4 items-center mb-[30px]">
          <Search queryData={queryData} />
        </div>

        <ul className="nav--cta row-start-1 flex col-span-2 md:col-span-4 h-[96px] items-center justify-end">
          <li className="md:flex">
            <a
              href="mailto:consulting@rentalguideai.com"
              className="btn--rounded btn-home-page btn--border btn--inverted"
            >
              Contact
            </a>
          </li>
        </ul>
      </NavStyles>
    </Container>
  );
};

export default Nav;
