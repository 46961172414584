import { media } from "./media";
import React from "react";
import styled, { createGlobalStyle, css } from "styled-components";
import { device } from "../components/breakpoint";

const GlobalStyles = createGlobalStyle`
  :root {
    --white: #fff;
    --primary-dark: #1E2024;
    --eyebrow:  #EE82EE;
    --primary: #c900ff;

    --c1: #fff;
    --c2: #381D2A;
    --c3: #3E6990;
    --c4: #E3170A;
    --c5: #F7B32B;
  }


  * {
    box-sizing: border-box;
  }
  
  html {
    overflow-x: hidden;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    -webkit-font-smoothing: antialiased;
    font-size: 16px;
  }
  body, html {
    min-height: 100vh;
  }

  body {
    position: relative;
  }

  .light-icon {
    path {
      fill: #fff;
    }
  }
  .dark-icon {
    path {
      fill: var(--primary-dark)
    }
  }



  body.dark {
    background: var(--primary-dark);
  }

  html.is-scroll-disabled-nav body {
    height: 100vh;
    overflow: hidden;
    .nav--container {
      &.nav-up {
        transform: translateY(0);
      }
    }
  }
  html.is-scroll-disabled body {
    height: 100vh;
    overflow: hidden;
  }
  
  .nav--container {
    width: 100%;
    opacity: 0;
    transition: all 0.3s linear;
  }
  


  .gatsby-image-wrapper img[src*=base64\\,] {
    image-rendering: -moz-crisp-edges;
    image-rendering: pixelated;
  }

  img {
    max-width: 100%;
  }
  
  a {
      transition: all .15s linear;
      cursor: pointer;
      text-decoration: none;
  }
  
  hr {
    border-color: var(----primary-dark);
  }

  .color--white {
    color: var(--white);
  }


  

  .bg--primary-dark {
    background-color: var(--primary-dark);
  }
  .bg--white {
    background-color: var(--white);
  }

  

  
`;

export default GlobalStyles;
